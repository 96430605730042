<script>
//import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Update Flight Status",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    //DatePicker,
    Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Update Flight Status",
      items: [],
      value1: null,
      value2: null,
      value3: null,
      value4: null,
      valueconsfind: null,
      options_consfind: [
      ],
      valuegoodstype: null,
      options_goodstype: [
        "General Cargo",
        "Perishable",
        "Live Fish/Benur",
      ],
      autoClose: "",
      value_vendor: null,
      options_vendor: [
        "Air Asia",
        "Lion Air",
        "Tri-MG",
        "Trigana", "Hercules"
      ],
      options_port: [
        "CGK - Soekarno-Hatta, Banten",
        "HLP – Halim Perdanakusuma, Jakarta",
        "JOG – Yogyakarta",
        "PLM – Palembang",
        "SOC – Solo",
        "SUB – Surabaya"
      ],
      options_flight: [
        "GM705 12:00-14:30 Tri-MG"
      ],
      value_routefrom: null,
      value_routeto: null,
      options_route: [
        "Banjarmasin (BDJ)",
        "Jakarta (CGK)",
        "Jakarta (HLP)",
        "Makassar (UPG)",
        "Medan (KNO)"
      ],
      value_flightupdatetype: null,
      options_flightupdatetype: [
        "None",
        "Data Flight",
        "Data Schedule",
        "Data Flight and Schedule"
      ],
      value_flightstatus: null,
      options_flightstatus: [
        "Delay",
        "RTB (Return To Base)",
        "Cancel",
        "Change Flight"
      ],
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Flight Status"
                    label-for="customer_code"
                  >
                    <multiselect id="" name="" v-model="value_flightstatus" :options="options_flightstatus"></multiselect>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Update Type"
                    label-for="customer_code"
                  >
                    <multiselect id="" name="" v-model="value_flightupdatetype" :options="options_flightupdatetype"></multiselect>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Remarks"
                    label-for="customer_code"
                  >
                   <b-form-input id="" name="" value=""></b-form-input>
                  </b-form-group>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label>Vendor</label><br />
                  <multiselect v-model="value_vendor" :options="options_vendor"></multiselect>
                </div>
                <div class="form-group mb-3">
                  <label>Flight No</label><br />
                  <b-form-input id="" name="" value=""></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Departure Time</label><br />
                  <date-picker v-model="datetime" :first-day-of-week="1" lang="en" name="mvs_datefrom" id="mvs_datefrom"></date-picker>
                </div>
                <div class="form-group mb-3">
                  <label>Arrival Time</label><br />
                  <date-picker v-model="datetime" :first-day-of-week="1" lang="en" name="mvs_datefrom2" id="mvs_datefrom2"></date-picker>
                </div>
                <div class="form-group mb-3">
                  <label>Duration</label><br />
                  <b-form-input id="" name="" value=""></b-form-input>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group mb-3">
                  <label>Route From</label><br />
                  <multiselect v-model="value_routefrom" :options="options_route"></multiselect>
                </div>
                <div class="form-group mb-3">
                  <label>Route To</label><br />
                  <multiselect v-model="value_routeto" :options="options_route"></multiselect>
                </div>
                <div class="form-group mb-3">
                  <label>Number of Transit</label><br />
                  <b-form-input id="" name="" value=""></b-form-input>
                </div>
                <div class="form-group mb-3">
                  <label>Tariff PerKg</label><br />
                  <b-form-input id="" name="" value=""></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <div class="table-responsive" style="height:250px">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th width="180">SMU No.</th>
                          <th>Collies</th>
                          <th>Total Chargeable Weight</th>
                          <th>Customer</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                        
                        <tr>
                          <th scope="row">1</th>
                          <td class="text-right"></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td>
                            <b-button size="sm" variant="danger">
                              <i class="ri-delete-bin-line"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>